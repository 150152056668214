import React from "react";


const Content = ({ className, color, background, pos, ...rest }) => {
  return (
    <>
        <svg className={`${pos} wave`} id="shape-1" style={{backgroundColor:"transparent"}} fill={color} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="1198.44" height="120" preserveAspectRatio="none" viewBox="0 0 1198.44 157.5">
            <title>wave</title>
            <path className="shape-1" d="M1198.44,157.33S956,92.59,529.63,91.37q-39.87-.12-76-1.22C90.82,79.25.29,0,.29,0L0,157.5Z"></path>
          </svg> 
    </>
  );
};

export default Content;
