import React from "react";

const Pricing = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container" style={{maxWidth:"90%"}}>
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-12">
            <div
              className="text-center mb-10 mb-lg-10 dark-mode-texts"
              data-aos="fade-up"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <h2 className="text-black font-size-10 letter-spacing-n83">
                Flexible price plans<br />for any business
              </h2>
              <h3 style={{color:"#666"}} className="font-size-7">Includes <span className="Links counter">Dynamic Qr Codes</span>, <span className="Popups counter">Popups</span>, <span className="Images counter">Images</span> &amp; <span className="Videos counter">Videos</span></h3>
            </div>
          </div>
        </div>
        {/* section title */}
        {/* Pricing Table */}
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12">
                  <div className="row justify-content-center">
                    {/* Single table */}
                    <div className="col-xl-3 col-md-6 col-sm-8 col-xs-10">
                      <div
                        className="price-card bg-white rounded-10 pl-8 pl-xs-8 pr-6 pr-xs-11 pt-8 pt-xs-10 pb-7 pb-xs-12 mb-13 mb-lg-10"
                        data-aos="fade-up"
                        data-aos-delay={500}
                        data-aos-once="true"
                      >
                        <div className="mb-9">
                          <h4 className="font-size-7 Videos">
                            Starter
                          </h4>
                          <p className="font-size-4 mb-0 text-stone">
                            Experience the suite
                          </p>
                        </div>
                        <h3 className="font-size-10 mb-9 text-dark-cloud letter-spacing-n83">
                         £14.99<span className="font-size-5">/month</span>
                        </h3>
                        <ul className="list-unstyled font-size-4 text-dark-cloud">
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 text-white" />
                             Static QR Code Access
                          </li>                                                     
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 text-white" />
                            1 Interactive Image
                          </li>  
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 text-white" />
                            1 Popup
                          </li>       
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 text-white" />
                            1 Interactive Video (250 Views)
                          </li>                                                                                                                                                     
                          <li className="mb-6">
                          <i className="fa fa-check mr-4 text-white" />
                            Digma Branding
                          </li>
                        </ul>
                        <div className="mt-11">
                          <a
                            className="btn bg-video text-white rounded-5 btn-xl h-55"
                            href="https://app.digma.io"
                          >
                            Try for free
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Single table */}
                    {/* Single table */}
                    <div className="col-xl-3 col-md-6 col-sm-8 col-xs-10">
                      <div
                        className="price-card bg-white rounded-10 pl-8 pl-xs-8 pr-6 pr-xs-11 pt-8 pt-xs-10 pb-7 pb-xs-12 mb-13 mb-lg-10"
                        data-aos="fade-up"
                        data-aos-delay={800}
                        data-aos-once="true"
                      >
                        <div className="mb-9">
                          <h4 className="font-size-7 Images">
                            Standard
                          </h4>
                          <p className="font-size-4 mb-0 text-stone">
                            Perfect for campaigns
                          </p>
                        </div>
                        <h3 className="font-size-10 mb-9 text-dark-cloud letter-spacing-n83">
                          £49<span className="font-size-5">/month</span>
                        </h3>
                        <ul className="list-unstyled font-size-4 text-dark-cloud">
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 bg-image text-white" />
                              DAM Access (10 GB Storage)
                          </li>       
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 bg-image text-white" />
                              Dynamic QR Code Access
                          </li>                                                       
                          <li className="mb-6">
                              <i className="fa fa-check mr-4 bg-image text-white" />
                              10 Interactive Images
                          </li> 
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 bg-image text-white" />
                            Unlimited Popups
                          </li>      
                          <li className="mb-6">
                        <i className="fa fa-check mr-4 bg-image text-white" />
                            10 Interactive Video (1k views)
                          </li>                                                                                                                                  
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 bg-image border-0 text-white" />
                            Digma Branding
                          </li>
                        </ul>
                        <div className="mt-11">
                          <a
                            className="btn bg-image text-white rounded-5 btn-xl h-55"
                            href="https://app.digma.io"
                          >
                            Try for free
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Single table */}
                    {/* Single table */}
                    <div className="col-xl-3 col-md-6 col-sm-8 col-xs-10">
                      <div
                        className="price-card bg-white rounded-10 pl-8 pl-xs-8 pr-6 pr-xs-11 pt-8 pt-xs-10 pb-7 pb-xs-12 mb-13 mb-lg-10"
                        data-aos="fade-up"
                        data-aos-delay={1200}
                        data-aos-once="true"
                      >
                        <div className="mb-9">
                          <h4 className="font-size-7 Forms">
                            Professional
                          </h4>
                          <p className="font-size-4 mb-0 text-stone">
                            For businesses
                          </p>
                        </div>
                        <h3 className="font-size-10 mb-9 text-dark-cloud letter-spacing-n83">
                          £299<span className="font-size-5">/month</span>
                        </h3>
                        <ul className="list-unstyled font-size-4 text-dark-cloud">
                        <li className="mb-6">
                        <i className="fa fa-check mr-4 bg-form text-white" />
                            DAM Access (50 GB Storage)
                          </li>       
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 bg-form text-white" />
                            Dynamic QR Code Access
                          </li>                            
                          <li className="mb-6">
                        <i className="fa fa-check mr-4 bg-form text-white" />
                            Bespoke Video Player
                          </li>                                                                               
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 bg-form text-white" />
                            Unlimited Popups
                          </li>    
                          <li className="mb-6">   
                          <i className="fa fa-check mr-4 bg-form border-0 text-white" />
                            Unlimited Videos &amp; Images
                          </li>                          
                                                                    
                          <li className="mb-6">
                          <i className="fa fa-check mr-4 bg-form border-0 text-white" />
                            Fully Whitelabeled
                          </li>
                        </ul>
                        <div className="mt-11">
                          <a
                            className="btn bg-form rounded-5 text-white btn-xl h-55"
                            href="https://app.digma.io"
                          >
                            Try for free 
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Single table */}
                    {/* Single table */}
                    <div className="col-xl-3 col-md-6 col-sm-8 col-xs-10">
                      <div
                        className="price-card bg-white rounded-10 pl-8 pl-xs-8 pr-6 pr-xs-11 pt-8 pt-xs-10 pb-7 pb-xs-12 mb-13 mb-lg-10"
                        data-aos="fade-up"
                        data-aos-delay={1500}
                        data-aos-once="true"
                      >
                        <div className="mb-9">
                          <h4 className="font-size-7 Links">
                            Enterprise
                          </h4>
                          <p className="font-size-4 mb-0 text-stone">
                            Tailored For agencies
                          </p>
                        </div>
                        <h3 className="font-size-10 mb-9 text-dark-cloud letter-spacing-n83">
                          POA<span className="font-size-5">/month</span>
                        </h3>
                        <ul className="list-unstyled font-size-4 text-dark-cloud">
                        <li className="mb-6">
                        <i className="fa fa-check mr-4 bg-link border-0 text-white" />
                            DAM Access (POA Storage)
                          </li>               
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 bg-link text-white" />
                            Dynamic QR Code Access
                          </li>                              
                          <li className="mb-6">
                          <i className="fa fa-check mr-4 bg-link border-0 text-white" />
                            Bespoke Video Player
                          </li>                                              
                          <li className="mb-6">
                            <i className="fa fa-check mr-4 bg-link text-white" />
                            Unlimited Popups
                          </li>  
                          <li className="mb-6">
                          <i className="fa fa-check mr-4 bg-link border-0 text-white" />
                            Unlimited Videos &amp; Images
                          </li>         
                                      
                          <li className="mb-6">
                          <i className="fa fa-check mr-4 bg-link border-0 text-white" />
                            Fully Whitelabeled
                          </li>
                        </ul>
                        <div className="mt-11">
                          <a
                            className="btn bg-link rounded-5 text-white btn-xl h-55"
                            href="https://app.digma.io"
                          >
                            Try for free
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Single table */}                                        
                  </div>
            </div>
          </div>
        {/* End Pricing Table */}
      </div>
    </div>
  );
};

export default Pricing;
