import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import PageWrapper from '../../components/PageWrapper';
import Hero from '../../sections/real-estate/Hero';
import Wave from '../../sections/common/Wave';
import Content1 from '../../sections/real-estate/Content1';
import Pricing from '../../sections/home4/Pricing';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Commit from '../../sections/common/Commit';
import SEO from '../../components/SEO';
import imgC from '../../assets/image/metaimg/default.jpg';
import Popup from 'reactjs-popup';
import gif from '../../assets/image/animated/realestate.gif';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const PricingPage = () => {
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (prevPos.y > currPos.y) {
      setShowReveal(false);
    } else {
      setShowReveal(true);
    }
  });
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-left',
          headerFluid: false,

          footerStyle: 'digma',
        }}
      >
        <SEO
          title="Real Estate Virtual Viewings and Tours"
          description="Let potential buyers view the property virtually. Digma compliments drone or gimbal footage for a unique experience."
          image={imgC}
        />
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">
                  Home
                </Link>
                <span className="breadcrumb__separator" aria-hidden="true">
                  {' '}
                  /{' '}
                </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">
                  Industries
                </Link>
                <span className="breadcrumb__separator" aria-hidden="true">
                  {' '}
                  /{' '}
                </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/real-estate">
                  Real Estate
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        <Hero />
        <Wave color="#F7F9FC" />
        <Content1 className="grey pt-10 mb-10 pt-md-20 pt-lg-15 overflow-hidden position-relative" />
        <Pricing />
        <div className="d-none d-md-block text-center pb-10 pt-5">
          <AniLink
            className="font-size-7"
            paintDrip
            hex="#2196f3"
            to="/pricing"
          >
            View full pricing details and plan comparison
          </AniLink>
        </div>
        <div style={{ overflow: 'hidden', background: '#f7f9fc' }}>
          <Commit />
        </div>
        <Popup
          trigger={(open) => (
            <button
              className={
                open
                  ? 'hide'
                  : `d-none d-md-flex btn bg-image btn-welcome btn-xl rounded-10 p-0 font-size-3 text-white ${
                      showScrolling ? 'hide' : ''
                    } ${showReveal ? ' ' : ''}`
              }
            >
              <div className="welcome-note">Real Estate Demo</div>
              <img src={gif} alt="Real Estate Demo" />
            </button>
          )}
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <div className="content">
                <Helmet>
                  <link
                    href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=pop'}
                    rel="stylesheet"
                  />
                  <script
                    src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=pop'}
                  ></script>
                </Helmet>
                <div
                  className="digma"
                  data-projectid="F264F5EB-6ABA-4D68-BAD9-1B8EE7AFE480"
                ></div>
              </div>
            </div>
          )}
        </Popup>
      </PageWrapper>
    </>
  );
};
export default PricingPage;
