import React from "react";
import imgM from "../../assets/image/hero/hand-phone.png";
import qr from "../../assets/qr-images/realestate.png";

const Content1 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row justify-content-center justify-content-lg-start">
          {/* Image Section */}
          <div className="col-xl-6 col-xs-6">
            <div>
              <div className="img-2">
                <img
                  className="w-100"
                  src={imgM}
                  alt="Digma Interactive Real Estate"
                  data-aos="fade-right"
                  data-aos-delay={500}
                />
                <div className="text-center mt-10">
                  <h4 className="pb-0 mb-0">Check it out on mobile</h4>
                  <img src={qr} className="w-50 p-10" alt="" />
                </div>

              </div>
            </div>
          </div>
          {/* Content Section */}
          <div className="col-xl-6 col-xs-6">
            <div className="pt-7 pl-sm-5 pl-md-9 pl-lg-10">
              <h3
                className="font-size-11 mb-9 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18 "
                data-aos="fade-up"
                data-aos-delay={300}
              >
                Virtual Viewings
              </h3>
              <p
                className="font-size-7 mb-0 pr-xl-13"
                data-aos="fade-up"
                data-aos-delay={600}
              >Now is the time for virtual viewings. Compliment your drone or gimbal footage and stand out from your competition.</p>
              <p className="font-size-7 mt-7 pr-xl-13" data-aos="fade-up"
                data-aos-delay={600}>By using our high-tech overlays, you can turn all your video content into an Interactive Real Estate Brochure.   
              </p>
              <p
                className="font-size-7 mt-7 pr-xl-13"
                data-aos="fade-up"
                data-aos-delay={600}
              >Sharing visual information on Real Estate can help drive sales and Digma can help you supply all that important information. </p>
                           
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Content1;
